<template>
  <div id="background" :style="{ backgroundImage: 'url(' + imagepath() + ')'  }">
    <Header color="primary" />
    <div class="wrapper">
      <div id="content">
        <v-container align-center class="ma-0 pa-0 primary--text" fluid justify-center
                     style="font-family: Raleway; text-transform: uppercase">
          <v-row align="center" justify="center" style="text-transform: none; margin-bottom: 15vh">
            <v-col :style="{ fontFamily: 'Cherolina', fontSize: fontsize(1), fontWeight: 'Bold' }" cols="12" xs="12">
              Sabrina & Silas
            </v-col>
          </v-row>
          <v-row v-if="this.$vuetify.breakpoint.smAndDown" align="center" justify="center" no-gutters style="text-transform: none; margin-bottom: 15vh;">
            <v-col :style="{ fontFamily: 'Cherolina', fontSize: fontsize(1), fontWeight: 500, textTransform: 'none' }"
                   align="center" cols="12" justify="center"
                   sm="12">
              Wir sagen Ja!
            </v-col>
          </v-row>
          <v-row v-if="this.$vuetify.breakpoint.mdAndUp"  align="center" justify="center" no-gutters style="text-transform: none; margin-bottom: 15vh;">
            <v-col :style="{ fontFamily: 'Cherolina', fontSize: fontsize(1), fontWeight: 500, textTransform: 'none' }"
                   align="center" cols="8" justify="center"
                   sm="4">
              Wir sagen
            </v-col>
            <v-col cols="0" sm="3">

            </v-col>
            <v-col :style="{ fontFamily: 'Cherolina', fontSize: fontsize(1), fontWeight: 500, textTransform: 'none' }"
                   align="center" cols="2" justify="center"
                   sm="5">
              Ja!
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="0" xl="8" lg="7" md="6" sm="0" />
            <v-col
                :style="{ fontFamily: 'Raleway', fontSize: fontsize(2), fontWeight: 500, textTransform: 'uppercase'}"
                cols="12" lg="5" md="6" sm="12"
                xl="4">
              {{ formatNum(hochzeitsdatum.getUTCDate()) }}. {{ getMonthAsString(hochzeitsdatum.getUTCMonth()) }}
              {{ hochzeitsdatum.getUTCFullYear() }}
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="0" lg="7" md="6" sm="0" xl="8"/>
            <v-col
                :style="{ fontFamily: 'Raleway', fontSize: fontsize(3), fontWeight: 500, textTransform: 'uppercase'}"
                cols="12" lg="5" md="6" sm="12"
                xl="4">
              <counter :date="hochzeitsdatum"></counter>
            </v-col>
          </v-row>
        </v-container>

      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import counter from '../Helpers/Counter.vue'
import header from '../Header/Header.vue'
import footer from '../Footer/Footer.vue'
import sharedfunctions from "../../sharedfunctions";

export default {
  components: {
    'Counter': counter,
    'Header': header,
    'Footer': footer,
  },
  data: () => ({
    hochzeitsdatum: new Date(2023, 1, 3, 14, 0, 0, 0)
  }),
  computed: {},
  methods: {
    // eslint-disable-next-line vue/return-in-computed-property
    fontsize(category) {
      switch (category) {
        case 1:
          switch (this.$vuetify.breakpoint.name) {
            case 'xs':
              return '50px'
            case 'sm':
              return '70px'
            case 'md':
              return '100px'
            case 'lg':
              return '100px'
            case 'xl':
              return '100px'
          }
          break;
        case 2:
          switch (this.$vuetify.breakpoint.name) {
            case 'xs':
              return 'large'
            case 'sm':
              return 'large'
            case 'md':
              return 'large'
            case 'lg':
              return 'x-large'
            case 'xl':
              return 'x-large'
          }
          break;
        case 3:
          switch (this.$vuetify.breakpoint.name) {
            case 'xs':
              return 'small'
            case 'sm':
              return 'small'
            case 'md':
              return 'small'
            case 'lg':
              return 'large'
            case 'xl':
              return 'large'
          }
          break;
      }
    },
    fontcolor() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 'white'
        case 'sm':
          return 'white'
        case 'md':
          return 'white'
        case 'lg':
          return 'white'
        case 'xl':
          return 'white'
      }
    },
    imagepath() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return require("../../assets/home/6.jpg");
        case 'sm':
          return require("../../assets/home/6.jpg");
        case 'md':
          return require("../../assets/home/10_cp.jpg");
        case 'lg':
          return require("../../assets/home/10_cp.jpg");
        case 'xl':
          return require("../../assets/home/10_cp.jpg");
      }

    },
    formatNum: num => (num < 10 ? "0" + num : num),
    getMonthAsString(month) {
      switch (month) {
        case 0:
          return "Januar";
        case 1:
          return "Februar";
        case 2:
          return "März";
        case 3:
          return "April";
        case 4:
          return "Mai";
        case 5:
          return "Juni";
        case 6:
          return "Juli";
        case 7:
          return "August";
        case 8:
          return "September";
        case 9:
          return "Oktober";
        case 10:
          return "November";
        case 11:
          return "Dezember";
      }
    },
    initColorScheme() {
      if (this.isMobile) {
        this.updateDarkMode(true);
      } else {
        this.updateDarkMode(true);
      }
    },
    // eslint-disable-next-line no-unused-vars
    myEventHandler(e) {
      if (this.isMobile) {
        this.updateDarkMode(true);
      } else {
        this.updateDarkMode(true);
      }
    }
  },
  beforeMount(){
    this.$vuetify.theme.dark = true;
  },
  created(){
    window.addEventListener("resize", this.myEventHandler);
    this.isMobile = sharedfunctions.isMobile;
    this.updateDarkMode = sharedfunctions.updateDarkMode;
    this.initColorScheme();
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
};
</script>

<style>

</style>
